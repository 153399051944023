import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuUserExtraFeaturesService } from '@core/services/au-user-extra-features.service';
import { isAiChatOpened } from '@app/layout/state/layout.selectors';
import { openAiChat } from '@app/layout/state/layout.actions';
import { hasNewNotifications } from '@app/core/notifications/state/notifications.selectors';
import { Router } from '@angular/router';
import config from '@app/configs/au-main-config';
import { loadNotifications } from '@app/core/notifications/state/notifications.actions';

/**
 * May the God bless that person who will try to make it standalone
 * P.S. You'll face pain and suffering with totally unuseful stacktrace of error that
 * is generally produced because of some module imports especially au-notifications
 * If it will tell you about cmms-table-configs - it's a lie >_<
 **/
@Component({
	selector: 'topbar-action-buttons',
	templateUrl: './topbar-action-buttons.component.html',
	styleUrls: ['./topbar-action-buttons.component.scss'],
	standalone: false,
})
export class TopbarActionButtonsComponent {
	private readonly store$ = inject(Store);
	private readonly userExtraFeaturesService = inject(AuUserExtraFeaturesService);
	private readonly router = inject(Router);

	unreadMessages$ = this.store$.select(hasNewNotifications);

	isAiChatEnabled = this.userExtraFeaturesService.isAiChatEnabled;

	isAiChatOpened = this.store$.selectSignal(isAiChatOpened);

	openAiChat() {
		this.store$.dispatch(openAiChat());
	}

	openNotifications() {
		this.router.navigate([config.auRoutes.inbox.link]);
		this.store$.dispatch(loadNotifications());
	}
}
