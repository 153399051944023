import { Component } from '@angular/core';
import { Router } from '@angular/router';

interface IContentConfig {
	title: string | null;
	descriptionFirstLine?: string;
	descriptionSecondLine?: string;
	descriptionThirdLine?: string;
	placeholderPath: string;
}

const defaultErrorContentConfig: IContentConfig = {
	title: `Whoopsidaisies! Well that's embarrassing..`,
	descriptionFirstLine: `Something seems to be wrong 🤔.`,
	descriptionSecondLine: `But you can bet we are working hard to make it right!`,
	descriptionThirdLine: `Give us a quick minute and we'll be back before you know it.`,
	placeholderPath: './assets/img/maskot/system-error.svg',
};

const gqlErrorContentConfig: IContentConfig = {
	title: null,
	placeholderPath: './assets/img/maskot/system-error.svg',
};

@Component({
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss'],
	standalone: false,
})
export class ErrorPageComponent {
	contentConfig: IContentConfig;

	constructor(private router: Router) {
		const errorState = this.router.getCurrentNavigation().extras.state?.error;

		if (errorState) {
			this.contentConfig = gqlErrorContentConfig;
			this.contentConfig.title = errorState;
		} else {
			this.contentConfig = defaultErrorContentConfig;
		}
	}
}
