import { RouterStateSerializer } from '@ngrx/router-store';
import { AuUtilsFunctions, CustomSerializer } from '@app/shared';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '@env/environment';
import { ErrorInterceptor } from '@app/core/interceptors/error.interceptor';
import { TokenInterceptor } from '@app/core/interceptors/token.interceptor';
import { inject, provideAppInitializer } from '@angular/core';

export function getAppProviders(): { [key: string]: any }[] {
	let providers: { [key: string]: any }[] = [
		{ provide: RouterStateSerializer, useClass: CustomSerializer },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
	];

	if (AuUtilsFunctions.isAutilityAuth()) {
		providers = addAuTokenInterceptor(providers);
	} else if (AuUtilsFunctions.isKeycloakAuth()) {
		providers = addKeycloakTokenInterceptor(providers);
	} else {
		throw new Error('Wrong Authentication provider name!');
	}

	return providers;
}

function addAuTokenInterceptor(providers) {
	providers.push({
		provide: HTTP_INTERCEPTORS,
		useClass: TokenInterceptor,
		multi: true,
	});
	return providers;
}

function addKeycloakTokenInterceptor(providers) {
	providers.push(
		provideAppInitializer(() => {
			const initializerFn = initializeKeycloak(inject(KeycloakService));
			return initializerFn();
		})
	);

	return providers;
}

export function initializeKeycloak(keycloak: KeycloakService) {
	return () =>
		keycloak.init({
			config: {
				url: environment.keycloak.issuer,
				realm: environment.keycloak.realm,
				clientId: environment.keycloak.clientId,
			},
			initOptions: {
				onLoad: 'check-sso',
				silentCheckSsoRedirectUri:
					window.location.origin + environment.baseHref + '/assets/silent-check-sso.html',
			},
		});
}
