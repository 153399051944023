import {
	computed,
	DestroyRef,
	inject,
	Injectable,
	signal,
	WritableSignal,
} from '@angular/core';
import { IAiChatMessage } from '@app/ai-chat/interfaces/ai-chat-message.interface';
import { Observable, Subject } from 'rxjs';
import {
	IAiChatResponse,
	IAiChatSources,
} from '@app/ai-chat/interfaces/ai-chat-response.interface';
import { environment } from '@env/environment';
import { DatabaseService } from '@app/database/database.service';
import { IAiChat } from '@app/ai-chat/interfaces/ai-chat.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AiChatService {
	private readonly dbService = inject(DatabaseService);
	private readonly destroyRef = inject(DestroyRef);
	private readonly httpClient = inject(HttpClient);

	selectedBuilding: WritableSignal<string> = signal<string>(null);
	isBuildingSelected = computed(() => {
		return !!this.selectedBuilding();
	});
	aiChats: WritableSignal<IAiChat[]> = signal<IAiChat[]>([]);
	aiChatMessages: WritableSignal<IAiChatMessage[]> = signal<IAiChatMessage[]>([]);
	selectedChatId: WritableSignal<number> = signal<number>(null);

	isLoadingReply = signal<boolean>(false);

	activeChatChanged$ = new Subject<void>();

	private noBuildingSelectedReply = `Sorry, i can't proceed your request without defined building. Please select a building first`;

	async createNewChat() {
		const newChat: IAiChat = { name: 'New Chat', buildingId: null };
		const chatId = await this.addChat(newChat);
		this.loadChats(); // Refresh chat list
		this.selectActiveChat(chatId);
	}

	addChat(chat: IAiChat): Promise<number> {
		return this.dbService.aiChats.add(chat);
	}

	async deleteChatAndMessages(chatId: number): Promise<void> {
		await this.dbService.aiChats.delete(chatId);
		await this.dbService.aiChatMessages.where('chatId').equals(chatId).delete();
	}

	addMessage(message: IAiChatMessage): Promise<number> {
		return this.dbService.aiChatMessages.add(message);
	}

	async selectActiveChat(id: number) {
		this.selectedChatId.set(id);
		this.loadChatMessages(id);
		this.activeChatChanged$.next();
		const buildingId = await this.getBuildingIdByChatId(id);
		this.selectedBuilding.set(buildingId);
	}

	async loadChats() {
		const chats = await this.getAllChats();
		this.aiChats.set(chats.reverse());
	}

	async loadChatMessages(chatId: number) {
		const messages = await this.getMessagesForChat(chatId);
		this.aiChatMessages.set(messages);
	}

	async getBuildingIdByChatId(chatId: number): Promise<string | null> {
		const chat = await this.dbService.aiChats.get(chatId);
		return chat ? chat.buildingId : null;
	}

	async updateBuildingId(chatId: number, buildingId: string): Promise<void> {
		await this.dbService.aiChats.update(chatId, { buildingId: buildingId });
	}

	getAllChats(): Promise<IAiChat[]> {
		return this.dbService.aiChats.toArray();
	}

	getMessagesForChat(chatId: number): Promise<IAiChatMessage[]> {
		return this.dbService.aiChatMessages.where('chatId').equals(chatId).toArray();
	}

	selectChatBuilding(buildingId: string) {
		this.selectedBuilding.set(buildingId);
		this.updateBuildingId(this.selectedChatId(), buildingId);
		this.changeChatName(buildingId, this.selectedChatId());
	}

	submitRequest(text: string) {
		this.processMessage(text, 'user');
		this.isLoadingReply.set(true);
		this.processMessage(null, 'chat');

		if (!this.isBuildingSelected()) {
			setTimeout(() => {
				this.isLoadingReply.set(false);
				this.updateLastChatMessageInDB(this.noBuildingSelectedReply);
			}, 500);
			return;
		}

		//We need to update sources only after all chunks are received
		let sources: IAiChatSources[] = [];

		this.connectToChatStream(text)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: (response: IAiChatResponse) => {
					sources = response.details?.sources;
					this.updateLastMessage(response.answer);
				},
				error: (err: any) => {
					console.error('Error in subscription:', err);
					this.isLoadingReply.set(false);
				},
				complete: () => {
					console.info('Chat stream connection closed');
					const lastMessage = this.aiChatMessages()[this.aiChatMessages().length - 1];
					const content = lastMessage.content
						? lastMessage.content
						: 'Cannot process your request';
					// We specifically don't set the last message sources in signal so that on
					// complete we can set them from what we received as a first chunk
					this.updateLastChatMessageInDB(content, sources);
					this.isLoadingReply.set(false);
				},
			});
	}

	async processMessage(text: string, user: 'user' | 'chat') {
		const message: IAiChatMessage = {
			chatId: this.selectedChatId(),
			content: text,
			sender: user,
			time: new Date(),
		};
		await this.addMessage(message);
		this.loadChatMessages(this.selectedChatId());
	}

	updateLastMessage(text: string, sources?: IAiChatSources[]) {
		const lastMessage = structuredClone(
			this.aiChatMessages()[this.aiChatMessages().length - 1]
		);
		// Because null + text will be string 'nulltext' \0^0/
		lastMessage.content = !lastMessage.content ? text : lastMessage.content + text;

		if (sources) {
			lastMessage.sources = sources;
		}
		this.aiChatMessages.update(messages => {
			messages[messages.length - 1] = lastMessage;
			return messages;
		});
	}

	updateLastChatMessageInDB(text: string, sources?: IAiChatSources[]) {
		this.dbService.aiChatMessages
			.where('chatId')
			.equals(this.selectedChatId())
			.last()
			.then(lastMessage => {
				this.dbService.aiChatMessages.update(lastMessage.id, {
					content: text,
					sources,
				});
				this.loadChatMessages(this.selectedChatId());
			});
	}

	private changeChatName(buildingId: string, chatId: number) {
		this.dbService.buildings.get(buildingId).then(building => {
			this.dbService.aiChats.update(chatId, { name: building.name });
			this.loadChats();
		});
	}

	private connectToChatStream(queryText: string): Observable<IAiChatResponse> {
		const url = `${
			environment.cmmsHost
		}/api/ai_chat?query=${encodeURIComponent(queryText)}&location_id=${this.selectedBuilding()}`;
		return this.httpClient.get<IAiChatResponse>(url);
	}
}
