import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StoreModule } from '@ngrx/store';
import * as fromLayout from './state/layout.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { KeycloakService } from 'keycloak-angular';
import { AuPipesModule } from '@app/shared/pipes/au-pipes.module';
import { AuDirectivesModule } from '@shared/directives/au-directives.module';
import { HeaderComponent } from '@app/layout/header/header.component';
import { LayoutEffects } from '@app/layout/state/layout.effects';
import { AuNavSidebarComponent } from '@layout/au-nav-sidebar/au-nav-sidebar.component';
import { ApplicationLayoutRoutingModule } from '@app/layout/application-layout-routing.module';
import { ApplicationLayoutComponent } from '@app/layout/application-layout.component';
import { AuRightSidebarModule } from '@layout/au-right-sidebar/au-right-sidebar.module';
import { DatabasePlaygroundComponent } from './database-playground/database-playground.component';
import { NotificationsEffects } from '@core/notifications/state/notifications.effects';
import { TopbarActionButtonsComponent } from '@layout/header/topbar-action-buttons/topbar-action-buttons.component';
import { AuAvatarWithNameModule } from '@shared/ui-components';
import { AuBreadcrumbComponent } from '@core/au-breadcrumb/au-breadcrumb.component';
import { VersionCheckComponent } from '@app/core/version-check/version-check.component';
import { NotificationsModule } from '@app/core/notifications/notifications.module';
import { PrivacyPolicyComponent } from '@app/core/privacy-policy/privacy-policy.component';

@NgModule({
	declarations: [
		ApplicationLayoutComponent,
		DatabasePlaygroundComponent,
		HeaderComponent,
		TopbarActionButtonsComponent,
	],
	imports: [
		CommonModule,
		ApplicationLayoutRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		AuNavSidebarComponent,
		StoreModule.forFeature(fromLayout.layoutFeatureKey, fromLayout.layoutReducers),
		EffectsModule.forFeature([LayoutEffects, NotificationsEffects]),
		AuRightSidebarModule,
		MatSidenavModule,
		MatIconModule,
		MatMenuModule,
		MatListModule,
		MatSelectModule,
		MatButtonModule,
		MatRippleModule,
		AuDirectivesModule,
		AuPipesModule,
		PrivacyPolicyComponent,
		AuAvatarWithNameModule,
		AuBreadcrumbComponent,
		VersionCheckComponent,
		NotificationsModule,
	],
	providers: [KeycloakService],
})
export class ApplicationLayoutModule {}
