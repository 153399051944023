import { EnvironmentNames } from '@app/shared';

export interface IAuNavbarConfig {
	title: string; // Title should be unique
	id?: string;
	svgIcon?: string;
	/**
	 * If you don't provide routerLink in child it will use
	 * parent one and will not highlight it as active
	 */
	routerLink?: string;
	className?: string;
	hideForEnvironment?: [EnvironmentNames];
	children?: IAuNavbarConfig[];
	expanded?: boolean;
	/**
	 * Use onClickAction with caution as if you have router link
	 * it will do both, navigate and run this action. It is nearly impossible
	 * to do either clickAction and programmatic navigation or
	 * routerLink directive navigation. You'll face problem that it will not
	 * highlight active link if you've try to remove the directives and
	 * do it programmatically.
	 */
	onClickAction?: () => void;
	action?: INavbarActionConfig;
	page?: boolean;
}

export interface INavbarActionConfig {
	svgIcon: string;
	methodKey: string;
	className?: string;
	isProvidedMenuAction?: boolean;
}

export const auNavbarConfig = () => {
	return [
		{
			title: 'Dashboard',
			svgIcon: 'home',
			routerLink: 'dashboard',
			className: 'dashboard',
		},
		{
			title: 'Inbox',
			svgIcon: 'bell',
			routerLink: 'inbox',
			className: 'inbox',
		},
		{
			title: 'Operations',
			svgIcon: 'cmms',
			routerLink: 'cmms',
			className: 'cmms folder',
			expanded: true,
			page: false,
			children: [
				{
					title: 'Work Orders',
					routerLink: 'work-orders',
				},
				{
					title: 'Incidents',
					routerLink: 'incidents',
				},
				{
					title: 'Requests',
					routerLink: 'requests',
				},
				{
					title: 'Files',
					routerLink: 'files',
				},
				{
					title: 'Assets',
					routerLink: 'assets',
				},
				{
					title: 'Messages',
					routerLink: 'messages',
					className: 'cmms',
				},
				{
					title: 'Contacts',
					routerLink: 'contacts',
					className: 'cmms',
				},
			],
		},
		{
			title: 'Map',
			svgIcon: 'location-pin',
			routerLink: 'digital-twin',
			className: 'digital-twin',
		},
	] as IAuNavbarConfig[];
};
//This is a specific route that will replace main navigation on settings route
export const settingsNavbarConfig = () => {
	return [
		{
			title: 'Organization',
			svgIcon: 'organizationV2',
			routerLink: 'settings/organization',
			className: 'organization-settings',
			page: false,
			expanded: true,
			children: [
				{
					title: 'About',
					routerLink: 'about',
				},
				{
					title: 'Buildings',
					routerLink: 'portfolio',
					className: 'portfolio',
				},
				{
					title: 'Labels',
					routerLink: 'labels',
				},
				{
					title: 'Import',
					routerLink: 'import',
					expanded: true,
					page: false,
					children: [
						{
							title: 'Files',
							routerLink: 'files',
							className: 'files',
						},
					],
				},
				{
					title: 'User Management',
					routerLink: 'users',
					className: 'users',
				},
				{
					title: 'Email service',
					routerLink: 'email-service',
				},
			],
		},
		{
			title: 'My Settings',
			svgIcon: 'user',
			routerLink: 'settings/profile',
			className: 'user-settings',
			expanded: true,
			page: false,
			children: [
				{
					title: 'Edit Profile',
					routerLink: 'edit',
				},
				{
					title: 'Experiments',
					routerLink: 'experiments',
				},
				{
					title: 'Notifications',
					routerLink: 'notifications',
				},
			],
		},
	] as IAuNavbarConfig[];
};
