import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/configs/reducers.config';
import { LogIn } from '@app/auth/state/auth.actions';
import { getAuthTranslate } from '@auth/state/auth.selectors';
import { Subject } from 'rxjs';
import { AuPasswordInput } from '@shared/au-password-input';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	standalone: false,
})
export class LoginComponent extends AuPasswordInput implements OnInit, OnDestroy {
	form: UntypedFormGroup;
	authTranslation$: any;
	passwordErrorValidation =
		'You must enter a value min 8; password must contain at least 1 number';
	private destroy$ = new Subject<void>();

	constructor(
		private fb: UntypedFormBuilder,
		private store$: Store<IAppState>
	) {
		super();

		this.form = this.fb.group({
			email: ['', [Validators.required]],
			password: ['', [Validators.required]],
		});
	}

	ngOnInit() {
		this.authTranslation$ = this.store$.select(getAuthTranslate);
	}

	onSubmit() {
		if (this.form.valid) {
			const val = this.form.value;
			const payload = {
				email: val.email.toLowerCase(),
				password: val.password,
			};
			this.store$.dispatch(new LogIn(payload));
		}
	}

	get email() {
		return this.form.controls.email;
	}

	get password() {
		return this.form.controls.password;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
