import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { AuRightSidebarService } from '@core/services/au-right-sidebar.service';
import { ISidebarConfig } from '@layout/au-right-sidebar/right-sidebar.interfaces';
import { RightSidebarNavigationService } from '@layout/au-right-sidebar/right-sidebar-navigation.service';

@Component({
	selector: 'au-right-sidebar',
	templateUrl: './au-right-sidebar.component.html',
	styleUrls: ['./au-right-sidebar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false,
})
export class AuRightSidebarComponent implements OnInit {
	sidebarConfig$: Observable<ISidebarConfig>;
	isFullscreenMode = false;
	disableButton = false;
	destroy$ = new Subject<void>();
	@HostListener('window:keydown', ['$event'])
	keyEvent(event: KeyboardEvent) {
		if (event.key === 'Escape') {
			this.sidebarService.close();
		}
	}

	constructor(
		public sidebarService: AuRightSidebarService,
		private sidebarNavigationService: RightSidebarNavigationService
	) {}

	ngOnInit() {
		this.sidebarConfig$ = this.sidebarService.sidebarConfig$;
		this.subscribeToDisabled();
	}

	navigateToHome() {
		this.sidebarService.navigateToHome();
	}

	navigateToFirst(): void {
		this.sidebarService.navigateToNext();
	}

	navigateToPrevious(): void {
		this.sidebarService.navigateToPreviousPage();
	}

	close(): void {
		this.sidebarService.close();
	}

	minimize(): void {
		this.sidebarService.minimize();
	}

	fullscreen(): void {
		this.isFullscreenMode = true;
		this.sidebarService.fullscreen();
	}

	exitFullscreen(): void {
		this.isFullscreenMode = false;
		this.sidebarService.undoFullscreen();
	}
	subscribeToDisabled() {
		this.sidebarService.sidebarActionBtnDisabled$
			.pipe(takeUntil(this.destroy$))
			.subscribe(val => (this.disableButton = val));
	}

	cancel(): void {
		this.sidebarNavigationService.navigationData.subscribe(navigationData => {
			if (!navigationData || !navigationData.history.length) {
				// if navigation data not exist close sidebar
				this.sidebarService.close();
			} else {
				this.sidebarNavigationService.navigateToPreviousPage();
			}
		});
	}

	actionClicked(): void {
		this.sidebarService.sidebarActionBtnClicked$.next();
	}
}
