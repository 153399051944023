import {
	Component,
	Inject,
	LOCALE_ID,
	OnInit,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadSelectableOptions } from './state/layout.actions';
import { take } from 'rxjs/operators';
import { ILayoutState } from '@layout/state/layout.reducer';
import { AuProfileService } from '@app/core/services/au-profile.service';
import ISettings from '@shared/interfaces/settings.interface';
import { AuRightSidebarService } from '@core/services/au-right-sidebar.service';
import { MatDrawer } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { loadNotifications } from '@core/notifications/state/notifications.actions';
import { AuBreadcrumbsService } from '@app/core/services/au-breadcrumbs.service';
import { FileLoaderService } from '@app/shared/ui-components/au-file-loader/services/file-loader.service';

@Component({
	selector: 'app-main-layout',
	templateUrl: './application-layout.component.html',
	styleUrls: ['./application-layout.component.scss'],
	standalone: false,
})
export class ApplicationLayoutComponent implements OnInit {
	locales = [];
	settings: ISettings;
	showPolicyBar;
	navSidebarCollapsed$: Observable<boolean>;

	@ViewChild('rightSidebar', { static: true })
	private rightSidebar: MatDrawer;
	@ViewChild('mainContent', { static: true, read: ViewContainerRef })
	private mainContent: ViewContainerRef;

	constructor(
		@Inject(LOCALE_ID) public locale: string,
		private store$: Store<ILayoutState>,
		private profileService: AuProfileService,
		public sidebarService: AuRightSidebarService,
		public breadcrumbsService: AuBreadcrumbsService,
		private fileLoaderService: FileLoaderService
	) {}

	ngOnInit() {
		this.sidebarService.sidebar = this.rightSidebar;
		this.fileLoaderService.containerRef = this.mainContent;

		// TODO refactor this to dispatch only 1 action like layoutOpened() and handle necessary actions in the effect
		this.store$.dispatch(LoadSelectableOptions());
		this.store$.dispatch(loadNotifications());

		this.profileService.userProfile$.pipe(take(1)).subscribe(profile => {
			this.showPolicyBar = !profile?.policyAccepted;
		});
	}

	privacyPolicyAccepted(): void {
		this.showPolicyBar = false;
		this.profileService.policyAccepted().subscribe(res => {
			if (res.success) {
				this.profileService.setUserProfile(res.profile);
			}
		});
	}

	undoMinimize() {
		this.sidebarService.undoMinimize();
	}
}
