<div class="topbar-action-buttons-container">
	<div
		class="ai-chat-toggle-icon"
		*ngIf="isAiChatEnabled()"
		matRipple
		(click)="openAiChat()"
	>
		<mat-icon svgIcon="robot" class="accent-icon" [class.active]="isAiChatOpened()" />
	</div>
	<div class="header-notification-icon icon-clickable" (click)="openNotifications()">
		<mat-icon
			svgIcon="bell"
			class="accent-icon"
			[class.unread-marker]="unreadMessages$ | async"
		></mat-icon>
	</div>
</div>
