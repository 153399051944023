import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { isNavSidebarCollapsed } from '@layout/state/layout.selectors';
import { NavSidebarExpanded, NavSidebarCollapsed } from '@layout/state/layout.actions';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	standalone: false,
})
export class HeaderComponent implements OnInit, OnDestroy {
	private readonly store$ = inject(Store);

	isNavSidebarCollapsed: boolean;
	subscription: Subscription;

	ngOnInit() {
		this.subscription = this.store$
			.select(isNavSidebarCollapsed)
			.subscribe(isCollapsed => (this.isNavSidebarCollapsed = isCollapsed));
	}

	toggleSidebar() {
		this.isNavSidebarCollapsed
			? this.store$.dispatch(NavSidebarExpanded())
			: this.store$.dispatch(NavSidebarCollapsed());
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
