import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
	selector: 'au-privacy-policy',
	templateUrl: './privacy-policy.component.html',
	styleUrls: ['./privacy-policy.component.scss'],
	imports: [CommonModule, MatButtonModule],
})
export class PrivacyPolicyComponent {
	@Output() policyAccepted = new EventEmitter<void>();

	accept() {
		this.policyAccepted.emit();
	}
}
