import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NgForOf } from '@angular/common';

@Component({
	selector: 'xmass-snow',
	templateUrl: './xmass-snow.component.html',
	styleUrls: ['./xmass-snow.component.scss'],
	imports: [NgForOf],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XmassSnowComponent {}
