<div
	class="navigation-overlay"
	[class.collapsed]="isCollapsed"
	[class.transition]="enableTransition()"
	resizeListener
	(resized)="menuResized($event)"
	[style.width]="navbarWidth()"
>
	<div class="navbar-content">
		@if (!isSettingsRoute()) {
			<app-title />
		} @else {
			<div class="au-settings-title-container" (click)="navigateBack()">
				<div class="back-button">
					<mat-icon svgIcon="nav_arrow_backward" />
				</div>
				<p class="settings-title">Settings</p>
			</div>
		}
		<navbar-menu-items
			[menuItems]="navigationConfig()"
			[isSettingsRoute]="isSettingsRoute()"
		/>
	</div>

	<div class="version">
		<div>
			v{{ versionFE }}
			@if (newVersionAvailable()) {
				<span>{{ '> ' }}{{ newVersionAvailable() }}</span>
			}
		</div>
		<div>{{ versionBE }}</div>
	</div>
</div>
