import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/configs/reducers.config';
import { PasswordReset } from '@app/auth/state/auth.actions';
import { Observable, Subject } from 'rxjs';
import { getAuthTranslate, getEmailSentStatus } from '@app/auth/state/auth.selectors';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-recovery-password',
	templateUrl: './recovery-password.component.html',
	styleUrls: ['./recovery-password.component.scss'],
	standalone: false,
})
export class RecoveryPasswordComponent implements OnInit, OnDestroy {
	private destroy$: Subject<void> = new Subject<void>();
	form: UntypedFormGroup;
	emailSent$: Observable<any>;
	authTranslation$: any;

	constructor(
		private fb: UntypedFormBuilder,
		private store$: Store<IAppState>,
		private snackBar: MatSnackBar
	) {
		this.form = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
		});
	}

	ngOnInit() {
		this.authTranslation$ = this.store$.select(getAuthTranslate);
		this.emailSent$ = this.store$.select(getEmailSentStatus);
	}

	get email() {
		return this.form.controls.email;
	}

	onSubmit() {
		if (this.form.valid) {
			const val = this.form.value;
			const payload = {
				email: val.email,
			};
			this.store$.dispatch(new PasswordReset(payload));
		}
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
		// need to have it if duration is infinite
		this.snackBar.dismiss();
	}
}
