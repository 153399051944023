import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'menu-section-item-title',
	imports: [CommonModule, MatIconModule],
	templateUrl: './menu-section-item-title.component.html',
	styleUrls: ['./menu-section-item-title.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSectionItemTitleComponent {
	@Input({ required: true }) title: string;
	@Input({ required: true }) expanded: boolean;
	@Input() isActionPresent: boolean;
	@Input() isMenuItemsAvailable: boolean;
	@Input() alwaysExpanded: boolean = false;
	@Output() sectionExpanded = new EventEmitter<boolean>();

	toggleSectionExpand(): void {
		if (!this.isMenuItemsAvailable) {
			return;
		}
		this.expanded = !this.expanded;
		this.sectionExpanded.emit(this.expanded);
	}
}
