import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	inject,
	Input,
	OnInit,
	Output,
	signal,
	WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatabaseService } from '@app/database/database.service';
import { IOption } from '@shared/interfaces/selectable-options.interfaces';
import { AuPipesModule } from '@shared/pipes/au-pipes.module';
import { liveQuery } from 'dexie';
import { IBuilding } from '@app/shared/interfaces/building.interfaces';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuSearchInputComponent } from '@app/shared/ui-components/au-search-input/au-search-input.component';

@Component({
	selector: 'follow-buildings-menu',
	imports: [
		CommonModule,
		AuSearchInputComponent,
		AuPipesModule,
		MatProgressSpinnerModule,
	],
	templateUrl: './follow-buildings-menu.component.html',
	styleUrls: ['./follow-buildings-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FollowBuildingsMenuComponent implements OnInit {
	private readonly dbService = inject(DatabaseService);

	@Input() set followedBuildings(buildings: { id: string; name: string }[]) {
		this.selectedBuildingIds.set(buildings?.map(b => b.id));
		this.subscribeToBuildings();
	}

	@Output() followBuildingClicked = new EventEmitter<IOption>();

	filteredBuildings$ = liveQuery(async () => {
		return await this.dbService.buildings
			.where('id')
			.noneOf(this.selectedBuildingIds())
			.sortBy('name');
	});

	selectedBuildingIds: WritableSignal<string[]> = signal([]);
	notFollowedBuildings: WritableSignal<IBuilding[]> = signal([]);
	searchText = signal('');
	loading = signal(true);

	ngOnInit() {
		this.subscribeToBuildings();
	}

	followBuilding(building: IOption) {
		this.followBuildingClicked.emit({ id: building.id, name: building.name });
	}

	triggerClearSearch() {
		this.searchText.set('');
	}

	private subscribeToBuildings() {
		this.filteredBuildings$.subscribe(buildings => {
			if (!buildings.length) {
				return;
			}
			this.loading.set(false);
			this.notFollowedBuildings.set(buildings);
		});
	}
}
