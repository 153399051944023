import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class KeycloakGuestGuard {
	constructor(private router: Router) {}

	canActivate() {
		/** Nah mate. You are nothing to do inside the Auth module when you use keycloak.
		 * So just go away
		 */
		this.router.navigate(['/dashboard']);

		/**
		 * Also we need to return at least something because of guard implementation rules.
		 * But actually this false is redundant
		 */

		return false;
	}
}
