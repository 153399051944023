export default [
	{
		name: 'logo',
		link: './assets/icons/logo/logo.svg',
	},
	{
		name: 'logo_mini',
		link: './assets/icons/logo/logo_mini.svg',
	},
	{
		name: 'checkmark',
		link: './assets/icons/status/checkmark.svg',
	},
	{
		name: 'exclamation_mark',
		link: './assets/icons/status/exclamation_mark.svg',
	},
	{
		name: 'burger',
		link: './assets/icons/burger.svg',
	},
	{
		name: 'building',
		link: './assets/icons/building.svg',
	},
	{
		name: 'bell',
		link: './assets/icons/notifications.svg',
	},
	{
		name: 'user',
		link: './assets/icons/user.svg',
	},
	{
		name: 'person',
		link: './assets/icons/person.svg',
	},
	{
		name: 'user-fill',
		link: './assets/icons/user-fill.svg',
	},
	{
		name: 'users',
		link: './assets/icons/users.svg',
	},
	{
		name: 'users_group',
		link: './assets/icons/users_group.svg',
	},
	{
		name: 'sensor',
		link: './assets/icons/sensor.svg',
	},
	{
		name: 'pencil',
		link: './assets/icons/edit.svg',
	},
	{
		name: 'delete',
		link: './assets/icons/delete.svg',
	},
	{
		name: 'eye',
		link: './assets/icons/show.svg',
	},
	{
		name: 'eye_hide',
		link: './assets/icons/hide.svg',
	},
	{
		name: 'gear',
		link: './assets/icons/gear.svg',
	},
	{
		name: 'sign_out',
		link: './assets/icons/sign_out.svg',
	},
	{
		name: 'energy',
		link: './assets/icons/energy.svg',
	},
	{
		name: 'comfort',
		link: './assets/icons/comfort.svg',
	},
	{
		name: 'tasks',
		link: './assets/icons/tasks.svg',
	},
	{
		name: 'technical_status',
		link: './assets/icons/technical_status.svg',
	},
	{
		name: 'filter',
		link: './assets/icons/filter.svg',
	},
	{
		name: 'map_pin',
		link: './assets/icons/map_pin.svg',
	},
	{
		name: 'dashboard',
		link: './assets/icons/dashboard.svg',
	},
	{
		name: 'help',
		link: './assets/icons/help.svg',
	},
	{
		name: 'bar_chart',
		link: './assets/icons/bar_chart.svg',
	},
	{
		name: 'home',
		link: './assets/icons/home.svg',
	},
	{
		name: 'add',
		link: './assets/icons/plus.svg',
	},
	{
		name: 'addcircleplus',
		link: './assets/icons/addplusbtn.svg',
	},
	{
		name: 'sort',
		link: './assets/icons/sort.svg',
	},
	{
		name: 'filter-sort',
		link: './assets/icons/filter-sort.svg',
	},
	{
		name: 'timeline',
		link: './assets/icons/timeline.svg',
	},
	{
		name: 'timeline-active',
		link: './assets/icons/timeline-active.svg',
	},
	{
		name: 'kanban',
		link: './assets/icons/kanban.svg',
	},

	{
		name: 'kanban-active',
		link: './assets/icons/kanban-active.svg',
	},
	{
		name: 'table',
		link: './assets/icons/table.svg',
	},
	{
		name: 'table-active',
		link: './assets/icons/table-active.svg',
	},
	{
		name: 'sort_arrow_down',
		link: './assets/icons/arrow-sort-down.svg',
	},
	{
		name: 'sort_arrow_up',
		link: './assets/icons/arrow-sort-up.svg',
	},
	{
		name: 'sort_arrows',
		link: './assets/icons/arrows-sort.svg',
	},
	{
		name: 'arrow_backward',
		link: './assets/icons/arrow_backward.svg',
	},
	{
		name: 'arrow_outward',
		link: './assets/icons/arrow_outward.svg',
	},
	{
		name: 'nav_arrow_backward',
		link: './assets/icons/nav_arrow_backward.svg',
	},
	{
		name: 'nav_arrow_forward',
		link: './assets/icons/nav_arrow_forward.svg',
	},
	{
		name: 'home-nav',
		link: './assets/icons/home-nav.svg',
	},
	{
		name: 'arrow_down',
		link: './assets/icons/arrow_down.svg',
	},
	{
		name: 'arrow_forward',
		link: './assets/icons/arrow_forward.svg',
	},
	{
		name: 'arrow_dropdown',
		link: './assets/icons/arrow-dropdown.svg',
	},
	{
		name: 'clock',
		link: './assets/icons/time.svg',
	},
	{
		name: 'animated_clock',
		link: './assets/icons/animated_clock.svg',
	},
	{
		name: 'panorama',
		link: './assets/icons/panorama.svg',
	},
	{
		name: 'system_state',
		link: './assets/icons/system_state.svg',
	},
	{
		name: 'folder_open',
		link: './assets/icons/folder_open.svg',
	},
	{
		name: 'folder_closed',
		link: './assets/icons/folder_closed.svg',
	},
	{
		name: 'inverted-fill-triangle',
		link: './assets/icons/inverted-fill-triangle.svg',
	},
	{
		name: 'floor',
		link: './assets/icons/floor.svg',
	},
	{
		name: 'room',
		link: './assets/icons/room.svg',
	},
	{
		name: 'search',
		link: './assets/icons/search.svg',
	},
	{
		name: 'camera',
		link: './assets/icons/camera.svg',
	},
	{
		name: 'overall-good',
		link: './assets/icons/overall-notifications/overall-good.svg',
	},
	{
		name: 'overall-warning',
		link: './assets/icons/overall-notifications/overall-warn.svg',
	},
	{
		name: 'unloc_add_new_lock',
		link: './assets/icons/unloc_add_new_lock.svg',
	},
	{
		name: 'overall-bad',
		link: './assets/icons/overall-notifications/overall-bad.svg',
	},
	{
		name: 'lock',
		link: './assets/icons/lock.svg',
	},
	{
		name: 'unloc',
		link: './assets/icons/unloc-helper.svg',
	},
	{
		name: 'add-user',
		link: './assets/icons/user_add.svg',
	},
	{
		name: 'add-organization',
		link: './assets/icons/add_org.svg',
	},
	{
		name: 'drop-rate',
		link: './assets/icons/drop-rate.svg',
	},
	{
		name: 'warning-rate',
		link: './assets/icons/warning-rate.svg',
	},
	{
		name: 'rise-rate',
		link: './assets/icons/rise-rate.svg',
	},
	{
		name: 'super-admin-crown',
		link: 'assets/icons/super_admin_crown.svg',
	},
	{
		name: 'organization',
		link: 'assets/icons/organization.svg',
	},
	{
		name: 'organizationV2',
		link: 'assets/icons/organization_no_strokes.svg',
	},
	{
		name: 'refresh',
		link: 'assets/icons/refresh.svg',
	},
	//TODO replace all occurrence of this icon with the new one calendar-v2 also remove all the hacks with styles related to it
	//  Rename v2 to calendar after removal
	{
		name: 'calendar',
		link: 'assets/icons/calendar.svg',
	},
	{
		name: 'cmms',
		link: 'assets/icons/cmms/cmms.svg',
	},
	{
		name: 'workorder', // alias
		link: 'assets/icons/cmms/cmms.svg',
	},
	{
		name: 'incident',
		link: 'assets/icons/cmms/incident.svg',
	},
	{
		name: 'request',
		link: 'assets/icons/cmms/request.svg',
	},
	{
		name: 'asset',
		link: 'assets/icons/cmms/asset.svg',
	},
	{
		name: 'extension',
		link: 'assets/icons/extension.svg',
	},
	{
		name: 'info',
		link: 'assets/icons/info.svg',
	},
	{
		name: 'file',
		link: 'assets/icons/cmms/file.svg',
	},
	{
		name: 'files',
		link: 'assets/icons/cmms/files.svg',
	},
	{
		name: 'other',
		link: 'assets/icons/other.svg',
	},
	{
		name: 'attachment',
		link: 'assets/icons/attachment.svg',
	},
	{
		name: 'checklist',
		link: 'assets/icons/checklist.svg',
	},
	{
		name: 'file-document',
		link: 'assets/icons/file-types/document.svg',
	},
	{
		name: 'file-pdf',
		link: 'assets/icons/file-types/pdf.svg',
	},
	{
		name: 'file-audio',
		link: 'assets/icons/file-types/audio.svg',
	},
	{
		name: 'file-image',
		link: 'assets/icons/file-types/image.svg',
	},
	{
		name: 'file-video',
		link: 'assets/icons/file-types/video.svg',
	},
	{
		name: 'file-unknown',
		link: 'assets/icons/file-types/unknown.svg',
	},
	{
		name: 'file-presentation',
		link: 'assets/icons/file-types/ppt.svg',
	},
	{
		name: 'file-exel',
		link: 'assets/icons/file-types/exel.svg',
	},
	{
		name: 'file-msword',
		link: 'assets/icons/file-types/msword.svg',
	},
	{
		name: 'file-3d-scan',
		link: 'assets/icons/file-types/3d-scan.svg',
	},
	{
		name: 'file-bim',
		link: 'assets/icons/file-types/bim.svg',
	},
	{
		name: 'file-floor-plan',
		link: 'assets/icons/file-types/floor-plan.svg',
	},
	{
		name: 'file-cad',
		link: 'assets/icons/file-types/file-cad.svg',
	},
	{
		name: 'remove',
		link: 'assets/icons/remove.svg',
	},
	{
		name: 'information_round',
		link: 'assets/icons/round_information.svg',
	},
	{
		name: 'envelop',
		link: 'assets/icons/envelop.svg',
	},
	{
		name: 'kpi-timeseries',
		link: 'assets/icons/kpi-timeseries.svg',
	},
	{
		name: 'grouping',
		link: 'assets/icons/grouping.svg',
	},
	{
		name: 'count-range',
		link: 'assets/icons/count-range.svg',
	},
	{
		name: 'multiselect',
		link: 'assets/icons/multiselect.svg',
	},
	{
		name: 'speedometer',
		link: 'assets/icons/speedo.svg',
	},
	{
		name: 'text',
		link: 'assets/icons/text.svg',
	},
	{
		name: 'upgrade',
		link: 'assets/icons/upgrade.svg',
	},
	{
		name: 'download',
		link: 'assets/icons/download.svg',
	},
	{
		name: 'checked_good',
		link: './assets/icons/checked_good.svg',
	},
	{
		name: 'fullscreen',
		link: './assets/icons/fullscreen.svg',
	},
	{
		name: 'priority',
		link: 'assets/icons/priority.svg',
	},
	{
		name: 'multitext',
		link: 'assets/icons/multitext.svg',
	},
	{
		name: 'twin_settings',
		link: 'assets/icons/twin_settings.svg',
	},
	{
		name: 'hub',
		link: 'assets/icons/hub.svg',
	},
	{
		name: 'h_button',
		link: 'assets/icons/h_button.svg',
	},
	{
		name: 'b_button',
		link: 'assets/icons/b_button.svg',
	},
	{
		name: 'r_button',
		link: 'assets/icons/r_button.svg',
	},
	{
		name: 'm_button',
		link: 'assets/icons/m_button.svg',
	},
	{
		name: 'n_button',
		link: 'assets/icons/n_button.svg',
	},
	{
		name: 'f_button',
		link: 'assets/icons/f_button.svg',
	},
	{
		name: 'category',
		link: 'assets/icons/category.svg',
	},
	{
		name: 'routine',
		link: 'assets/icons/routine.svg',
	},
	{
		name: 'upload',
		link: 'assets/icons/upload.svg',
	},
	{
		name: 'tasks_completed',
		link: 'assets/icons/tasks-completed.svg',
	},
	{
		name: 'flag',
		link: 'assets/icons/flag.svg',
	},
	{
		name: 'tree',
		link: 'assets/icons/tree.svg',
	},
	{
		name: 'cancel',
		link: 'assets/icons/cancel.svg',
	},
	{
		name: 'edit',
		link: 'assets/icons/edit.svg',
	},
	{
		name: 'science',
		link: 'assets/icons/science.svg',
	},
	{
		name: 'report',
		link: 'assets/icons/report.svg',
	},
	{
		name: 'handshake',
		link: 'assets/icons/handshake.svg',
	},
	{
		name: 'demography',
		link: 'assets/icons/demography.svg',
	},
	{
		name: 'gavel',
		link: 'assets/icons/gavel.svg',
	},
	{
		name: 'stylus_note',
		link: 'assets/icons/stylus_note.svg',
	},
	{
		name: 'image',
		link: 'assets/icons/image.svg',
	},
	{
		name: 'table-view',
		link: 'assets/icons/table-view.svg',
	},
	{
		name: 'calendar-v2',
		link: 'assets/icons/calendar-v2.svg',
	},
	{
		name: 'ifc',
		link: 'assets/icons/ifc.svg',
	},
	{
		name: 'sidebar',
		link: 'assets/icons/sidebar.svg',
	},
	{
		name: 'upload_violet',
		link: 'assets/icons/upload-violet.svg',
	},
	{
		name: 'checklist-borderless',
		link: 'assets/icons/checklist-borderless.svg',
	},
	{
		name: 'link-nomapped',
		link: 'assets/icons/link-nomapped.svg',
	},
	{
		name: 'link-mapped',
		link: 'assets/icons/link-mapped.svg',
	},
	{
		name: 'frame',
		link: 'assets/icons/frame.svg',
	},
	{
		name: 'vector',
		link: 'assets/icons/vector.svg',
	},
	{
		name: 'add_coordinates',
		link: 'assets/digital-twin/icons/add_location_alt.svg',
	},
	{
		name: 'content_copy',
		link: 'assets/icons/content_copy.svg',
	},
	{
		name: 'add_incident',
		link: 'assets/digital-twin/icons/add-incident.svg',
	},
	{
		name: 'add_workorder',
		link: 'assets/digital-twin/icons/add-workorder.svg',
	},
	{
		name: 'digital-twin-sidebar',
		link: 'assets/digital-twin/icons/digital_twin_sidebar.svg',
	},
	{
		name: 'frame_inspect',
		link: 'assets/icons/frame_inspect.svg',
	},
	{
		name: 'progress_activity',
		link: 'assets/icons/progress_activity.svg',
	},
	{
		name: 'report_sign',
		link: 'assets/icons/report_sign.svg',
	},
	{
		name: 'portfolio',
		link: 'assets/icons/portfolio.svg',
	},
	{
		name: 'robot',
		link: 'assets/icons/robot.svg',
	},
	{
		name: 'content_copy',
		link: 'assets/icons/content_copy.svg',
	},
	{
		name: 'add_a_photo',
		link: 'assets/icons/add_a_photo.svg',
	},
	{
		name: 'add_picture',
		link: 'assets/icons/add_picture.svg',
	},
	{
		name: 'action_center',
		link: 'assets/icons/action_center.svg',
	},
	{
		name: 'doc_type',
		link: 'assets/icons/doctype.svg',
	},
	{
		name: 'apps',
		link: 'assets/icons/apps.svg',
	},
	{
		name: 'file_import',
		link: 'assets/icons/file_import.svg',
	},
	{
		name: 'arrow_right',
		link: 'assets/icons/arrow_right.svg',
	},
	{
		name: 'article',
		link: 'assets/icons/article.svg',
	},
	{
		name: 'l-key',
		link: 'assets/icons/keys/l-key.svg',
	},
	{
		name: 'c-key',
		link: 'assets/icons/keys/c-key.svg',
	},
	{
		name: 'd-key',
		link: 'assets/icons/keys/d-key.svg',
	},
	{
		name: 'a-key',
		link: 'assets/icons/keys/a-key.svg',
	},
	{
		name: 's-key',
		link: 'assets/icons/keys/s-key.svg',
	},
	{
		name: 'w-key',
		link: 'assets/icons/keys/w-key.svg',
	},
	{
		name: 'i-key',
		link: 'assets/icons/keys/i-key.svg',
	},
	{
		name: 'r-key',
		link: 'assets/icons/keys/r-key.svg',
	},
	{
		name: 'p-key',
		link: 'assets/icons/keys/p-key.svg',
	},
	{
		name: 'f-key',
		link: 'assets/icons/keys/f-key.svg',
	},
	{
		name: 'del-key',
		link: 'assets/icons/keys/del-key.svg',
	},
	{
		name: 'shift-key',
		link: 'assets/icons/keys/shift-key.svg',
	},
	{
		name: 'ctrl-key',
		link: 'assets/icons/keys/ctrl-key.svg',
	},
	{
		name: 'import_tool',
		link: 'assets/icons/import_tool.svg',
	},
	{
		name: 'more_vert',
		link: 'assets/icons/more_vertical.svg',
	},
	{
		name: 'remove-attachment',
		link: 'assets/icons/remove-attachment.svg',
	},
	{
		name: 'sharp-arrow-down',
		link: 'assets/icons/sharp-arrow-down.svg',
	},
	{
		name: 'folders-list',
		link: 'assets/icons/folders-list.svg',
	},
	{
		name: 'folder-opened-outlined',
		link: 'assets/icons/folder_open_outlined.svg',
	},
	{
		name: 'bolt',
		link: 'assets/icons/bolt.svg',
	},
	{
		name: 'accuracy-low',
		link: 'assets/icons/accuracy-low.svg',
	},
	{
		name: 'accuracy-medium',
		link: 'assets/icons/accuracy-medium.svg',
	},
	{
		name: 'accuracy-high',
		link: 'assets/icons/accuracy-high.svg',
	},
	{
		name: 'columns-view',
		link: 'assets/icons/columns-view.svg',
	},
	{
		name: 'label-icon',
		link: 'assets/icons/label-icon.svg',
	},
	{
		name: 'dragable',
		link: 'assets/icons/dragable.svg',
	},
	{
		name: 'email',
		link: 'assets/icons/email.svg',
	},
	{
		name: 'smart-suggest',
		link: 'assets/icons/smart_suggest.svg',
	},
	{
		name: 'group-by',
		link: 'assets/icons/group-by.svg',
	},
	{
		name: 'location-pin',
		link: 'assets/icons/location-pin.svg',
	},
	{
		name: 'smart-stars',
		link: 'assets/icons/smart_stars.svg',
	},
	{
		name: 'add-image',
		link: 'assets/icons/add_image.svg',
	},
	{
		name: 'label',
		link: 'assets/icons/label.svg',
	},
	{
		name: 'digital-twin',
		link: 'assets/digital-twin/icons/digital-twin.svg',
	},
	{
		name: 'share',
		link: 'assets/icons/actions/share.svg',
	},
	{
		name: 'calendar-disabled',
		link: 'assets/icons/calendar-disabled.svg',
	},
	{
		name: 'digital-twin-disabled',
		link: 'assets/digital-twin/icons/digital-twin-disabled.svg',
	},
	{
		name: 'mail',
		link: 'assets/icons/mail.svg',
	},
	{
		name: 'chat',
		link: 'assets/icons/chat.svg',
	},
	{
		name: 'link-external',
		link: 'assets/icons/link-external.svg',
	},
	{
		name: 'add-file',
		link: 'assets/icons/cmms/cmms-add-entity/file_add.svg',
	},
	{
		name: 'no-document-type',
		link: 'assets/icons/no-document-type.svg',
	},
];
