import { computed, inject, Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCurrentUser } from '@auth/state/auth.selectors';
import { IUser } from '@shared/interfaces/user.interface';

@Injectable({
	providedIn: 'root',
})
export class AuUserExtraFeaturesService {
	private readonly store$ = inject(Store);

	profile: Signal<IUser> = this.store$.selectSignal(getCurrentUser);

	isAiChatEnabled = computed(() => {
		const organization = this.profile()?.organization;
		return organization?.aiChatEnabled;
	});

	isViewsEnabled = computed(() => {
		return this.profile()?.organization?.viewsEnabled;
	});
}
